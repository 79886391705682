.letter {
    float: left;
    font-size: 2rem;
    color: #777;
}

.loader {
    position: absolute;
}
.loader .letter {
    animation-name: loading;
    animation-duration: 1.2s;
    animation-iteration-count: infinite;
    animation-direction: linear;
}

.l-1 {
    animation-delay: 0.48s;
}
.l-2 {
    animation-delay: 0.6s;
}
.l-3 {
    animation-delay: 0.72s;
}
.l-4 {
    animation-delay: 0.84s;
}
.l-5 {
    animation-delay: 0.96s;
}
.l-6 {
    animation-delay: 1.08s;
}
.l-7 {
    animation-delay: 1.2s;
}

@keyframes loading {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.data-table-lds-ring {
    display: inline-block;
    position: relative;
    width: 160px;
    height: 160px;
}
.data-table-lds-ring div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 160px;
    height: 160px;
    border: 2px solid #fff;
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: #777 transparent transparent transparent;
}
.data-table-lds-ring div:nth-child(1) {
    animation-delay: -0.45s;
}
.data-table-lds-ring div:nth-child(2) {
    animation-delay: -0.3s;
}
.data-table-lds-ring div:nth-child(3) {
    animation-delay: -0.15s;
}
