@keyframes dtCardZoomIn {
    0% {
        transform: scale(1);
    }
    100% {
        transform: scale(0.7);
    }
}
@keyframes dtCardZoomOut {
    0% {
        transform: scale(0.7);
        opacity: 0;
    }
    100% {
        transform: scale(1);
        opacity: 1;
    }
}
