@import "@silevis/reactgrid/styles.css";

/* ::-webkit-scrollbar {
    background-color: #fff;
    width: 16px;
}

::-webkit-scrollbar-track {
    background-color: #fff;
}
::-webkit-scrollbar-track:hover {
    background-color: #f4f4f4;
}
::-webkit-scrollbar-thumb {
    background-color: #babac0;
    border-radius: 16px;
    border: 5px solid #fff;
}
::-webkit-scrollbar-thumb:hover {
    background-color: #a0a0a5;
    border: 4px solid #f4f4f4;
}

::-webkit-scrollbar-button {
    display: none;
} */

.App {
    text-align: center;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.rg-celleditor input {
    border: 0;
}

.MuiDialogContentText-root {
    color: unset !important;
}
